import React, { useEffect } from 'react';
import styles from './Previews.module.css';

const Previews = () => {
  useEffect(() => {
    document.title = `Studio Lain | Plany wydawnicze`;
  }, []);
  return (
    <>
      <main className='container_page'>
        <h2 className='title'>Nasze plany wydawnicze</h2>
        <p className={styles.text_introduce}>
          <a
            href='https://blogokomiksach.blogspot.com/2023/12/studio-lain-podsumowanie-mijajacego.html'
            target='_blank'
            rel='noreferrer'
          >
            Przeczytaj wywiad:{' '}
            <b> Podsumowanie mijającego roku i zapowiedzi na 2024 r.</b>
          </a>
        </p>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/slaine-dragontamer.jpg'
              alt='Slaine: Pogramca Smoków'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Slaine: Pogramca Smoków</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Tytył oryginalny</li>
              <li>Slaine Dragontamer</li>
              <li>Scenariusz</li>
              <li>Pat Mills</li>
              <li>Ilustracje</li>
              <li>Leonardo Manco</li>
            </ul>
            <p>
              Przewidywana data polskiego wydania: <b>30 września 2024</b>
            </p>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://shop.2000ad.com/catalogue/GRN696'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link 2000 AD</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/tales-of-telguuth.webp'
              alt='Opowieści z Telguuth'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Opowieści z Telguuth</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Tytył oryginalny</li>
              <li>Tales of Telguuth</li>
              <li>Scenariusz</li>
              <li>Steve Moore </li>
              <li>Ilustracje</li>
              <li>
                Carl Critchlow, Clint Langley, Dean Ormston, Greg Staples, John
                Stokes, Jon Haward, Pat Wright, Paul Johnson, Siku, Simon Davis,
                Stefano Cardoselli, Dave Kendall I David Kendall
              </li>
            </ul>
            <p>
              Przewidywana data polskiego wydania: <b>15 października 2024</b>
            </p>

            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://shop.2000ad.com/catalogue/XB539'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link 2000 AD</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/altor.jpg' alt='Altor'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Altor</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Moebius</li>
              <li>Ilustracje</li>
              <li>Marc Bati</li>
            </ul>
            <p>
              Przewidywana data polskiego wydania:{' '}
              <b>30 października 2024</b>
            </p>
            <p>Wydanie zbiorcze, kompletne (razem ok. 370 stron).</p>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/serie-84-BD-Altor.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/abc.jpg' alt='A B C'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>ABC Warriors</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Pat Mills</li>
              <li>Ilustracje</li>
              <li>Clint Langley</li>
            </ul>
            <p>
              Przewidywana data polskiego wydania:{' '}
              <b>październik 2024</b>
            </p>
            <p>
              Wydanie zbiorcze ABC Warriors w skład którego wejdą: Wojna
              Volgańska, Powrót na Ziemię, Powrót na Marsa i powrót Ro-Busterów.
              Planujemy dwa zbiorcze tomy sprzedawane w jednym pakiecie.
            </p>
            <p>
              Więcej informacji na temat zagranicznych wydań:
              <br />
              <a
                href='https://shop.2000ad.com/catalogue/GRN453/abc-warriors-the-volgan-war-vol-01'
                target='_blank'
                rel='noreferrer'
              >
                <b> 2000 AD - The Volgan War Book I</b>
              </a>
              <br />
              <a
                href='https://shop.2000ad.com/catalogue/XB461/abc-warriors-the-volgan-war-vol-02'
                target='_blank'
                rel='noreferrer'
              >
                <b> 2000 AD - The Volgan War Book II</b>
              </a>
              <br />
              <a
                href='https://shop.2000ad.com/catalogue/GRN481/abc-warriors-the-volgan-war-vol-03'
                target='_blank'
                rel='noreferrer'
              >
                <b> 2000 AD - The Volgan War Book III</b>
              </a>
              <br />
              <a
                href='https://shop.2000ad.com/catalogue/XB496/abc-warriors-the-volgan-war-vol-04'
                target='_blank'
                rel='noreferrer'
              >
                <b> 2000 AD - The Volgan War Book IV</b>
              </a>
              <br />
              <a
                href='https://shop.2000ad.com/catalogue/XB541/abc-warriors-return-to-mars'
                target='_blank'
                rel='noreferrer'
              >
                <b> 2000 AD - Return to Mars</b>
              </a>
              <br />
              <a
                href='https://shop.2000ad.com/catalogue/XB575/abc-warriors-return-to-ro-busters'
                target='_blank'
                rel='noreferrer'
              >
                <b> 2000 AD - Return to Ro-Busters</b>
              </a>
            </p>
          </div>
        </div>



        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/arkhe-lailah.jpg'
              alt='Arkhé - Laïlah'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Arkhé - Laïlah</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Caza</li>
              <li>Ilustracje</li>
              <li>Caza</li>
            </ul>
            <p>
              Przewidywana data polskiego wydania: <b>połowa listopada 2024</b>
            </p>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/BD-Arkhe-INT-Arkhe-Lailah-437632.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>

                <p>
                  Album zbiera wszystkie krótkie i kultowe historie Cazy
                  stworzone na potrzeby magazynów Metal Hurlant i Heavy Metal.
                  Powiększony format, papier artic silk. Przy okazji informacja
                  dla kolekcjonerów - format będzie pasował do polskiej edycji
                  Kronik Arkadii od Lost in Time.
                </p>
              </a>
            </p>
          </div>
        </div>
      


        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/dans-lombre-du-soleil.webp'
              alt='W cieniu słońca'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>W cieniu słońca</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Tytył oryginalny</li>
              <li>Dans L'Ombre du Soleil</li>
              <li>Scenariusz</li>
              <li>Colin Wilson</li>
              <li>Ilustracje</li>
              <li>Colin Wilson</li>
            </ul>
            <p>
              Przewidywana data polskiego wydania: <b>koniec listopada 2024</b>
            </p>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/serie-557-BD-Dans-l-ombre-du-soleil.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>
              </a>
            </p>
          </div>
        </div>



        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/armie-zdobywcy.jpg'
              alt='Armie zdobywcy'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Armie zdobywcy (w kolorze)</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Tytył oryginalny</li>
              <li>Les Armées du conquérant</li>
              <li>Scenariusz</li>
              <li>Jean-Pierre Dionnet</li>
              <li>Ilustracje</li>
              <li>Jean-Claude Gal</li>
            </ul>
            <p>
              Przewidywana data polskiego wydania: <b>grudzień 2024</b>
            </p>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.humano.com/album/36189'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link Les Humanoïdes Associés</b>

                <p>
                  To komiks o dodruk którego najczęściej nas prosiliście. Mamy dwie informacje - dobrą i zła. Zła jest taka, że dodruku nie będzie. Dobra jest taka, że Armie zostaną wydane w zupełnie nowej - KOLOROWEJ - wersji. 
                </p>
                <p>Komiks nie będzie zawierał krótkiej historii <i>Katedra</i> z wersji czarno-białej.</p>
              </a>
            </p>
          </div>
        </div>


        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/komiks/seria-uniwersum-dredda/bezprawie-tom-1-witamy-w-badrocku_okladka-podstawowa-big.jpg'
              alt='Bezprawie 1'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Bezprawie 1 - dodruk</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Dan Abnett</li>
              <li>Ilustracje</li>
              <li>Phil Winslade</li>
            </ul>
            <p>
              Przewidywana data dodruku: <b>listopad 2024</b>
            </p>
            <p>
              Więcej informacji na temat pierwszego wydania:
              <a
                href='komiks/bezprawie-tom-1-witamy-w-badrocku'
              >
                <b> Link</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/darkness.jpg' alt='Darkness<'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Darkness</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Garth Ennis</li>
              <li>Ilustracje</li>
              <li>Marc Silvestri</li>
            </ul>
            <p>
              Przewidywana data polskiego wydania:{' '}
              <b>grudzień 2024</b>
            </p>
            <p>
              Complete Edition vol. 1 & 2, czyli kompletny run (0-40 zeszyty
              plus wszystkie 1/2 i crossovery z tego okresu).
            </p>
            <p>Więcej informacji na temat zagranicznego wydania:</p>

            <a
              href='https://www.bedetheque.com/serie-2638-BD-Darkness-Editions-USA.html'
              target='_blank'
              rel='noreferrer'
            >
              <b> Link BD</b>
            </a>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/vae-victis.jpg' alt='Vae Victis !'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Vae Victis !</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Simon Rocca</li>
              <li>Ilustracje</li>
              <li>Jean-Yves Mitton</li>
            </ul>
            <p>
              Przewidywana data polskiego wydania:{' '}
              <b>styczeń 2025</b>
            </p>
            <p>
              Wydanie będzie się składać z 3 tomów (każdy po ok. 200 stron)
              sprzedawanych w komplecie.
            </p>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/serie-1221-BD-Vae-Victis.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/solo-5.jpg' alt='Solo 4'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Solo 4</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Tytył oryginalny</li>
              <li>Solo: Marcher sans soulever de poussière</li>
              <li>Scenariusz</li>
              <li>Oscar Martin</li>
              <li>Ilustracje</li>
              <li>Oscar Martin</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/BD-Solo-Martin-Tome-5-Marcher-sans-soulever-de-poussiere-414088.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>
              </a>
            </p>
          </div>
        </div>

     

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/zenith.webp' alt='Zenith'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Zenith</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Grant Morrison </li>
              <li>Ilustracje</li>
              <li>Steve Yewell</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://shop.2000ad.com/catalogue/graphic-novels/zenith'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link 2000 AD</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/nemesis-the-warlock.webp'
              alt='Nemesis The Warlock'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Nemesis The Warlock</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Pat Mills</li>
              <li>Ilustracje</li>
              <li>Jesus Redondo i Kevin O’Neill</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://shop.2000ad.com/catalogue/graphic-novels/nemesis-the-warlock'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link 2000 AD</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/sinister-dexter.webp'
              alt='Sinister Dexter'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Sinister Dexter</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Dan Abnett</li>
              <li>Ilustracje</li>
              <li>Simon Davis</li>
            </ul>
            <p>
              Przewidywana data polskiego wydania: <b>II połowa 2024</b>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/berceuse-assassine.webp'
              alt='Mordercza kołysanka'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Mordercza kołysanka</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Tytył oryginalny</li>
              <li>Berceuse Assassine</li>
              <li>Scenariusz</li>
              <li>Tome</li>
              <li>Ilustracje</li>
              <li>Ralph Meyer</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/serie-95-BD-Berceuse-assassine.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>
              </a>
            </p>
          </div>
        </div>

    

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/tablevenus.jpg'
              alt='La table de Vénus'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>La table de Vénus</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>José Roosevelt</li>
              <li>Ilustracje</li>
              <li>José Roosevelt</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/BD-Table-de-Venus-INT-La-Table-de-Venus-28861.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>
              </a>
            </p>
          </div>
        </div>

      


        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/czwarta-sila.jpg'
              alt='La table de Vénus'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Czwarta siła</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Tytył oryginalny</li>
              <li>Le quatrième pouvoir</li>
              <li>Scenariusz</li>
              <li>Juan Giménez</li>
              <li>Ilustracje</li>
              <li>Juan Giménez</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/serie-592-BD-Quatrieme-pouvoir.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>

                <p>
                  {' '}
                  Czwarta siła - autorski komiks Juana Gimeneza - wreszcie w
                  całości i komplecie po polsku! Od Lain. Lata temu wydał tą
                  serię Egmont. Niestety niekompletną. Tylko 3 z 4 tomów. Lain
                  wyda teraz ten komiks w komplecie, w integralu zbierającym
                  cztery piękne albumy i z nowym tłumaczeniem.
                </p>
              </a>
            </p>
          </div>
        </div>

       

      

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/kingdom.jpg' alt='Kingdom'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Kingdom</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Dan Abnett</li>
              <li>Ilustracje</li>
              <li>Richard Elson</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://shop.2000ad.com/series/kingdom'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link 2000 AD</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/stone.jpeg'
              alt='Stone The Awakening'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Stone The Awakening</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Brian Haberlin</li>
              <li>Ilustracje</li>
              <li>Brian Haberlin, Whilce Portacio</li>
            </ul>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/hellcop.jpg' alt='Hellcop'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Hellcop</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Brian Haberlin</li>
              <li>Ilustracje</li>
              <li>Brian Haberlin, Geirrod van Dyke</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://imagecomics.com/comics/series/hellcop'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link imagecomics.com</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/sonata.jpg' alt='Sonata'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Sonata</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Brian Haberlin, David Hine</li>
              <li>Ilustracje</li>
              <li>Brian Haberlin, Geirrod van Dyke</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://imagecomics.com/comics/series/sonata'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link imagecomics.com</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/fathom.jpg' alt='Fathom'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Fathom</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Michael Turner</li>
              <li>Ilustracje</li>
              <li>Michael Turner</li>
            </ul>
            <p>
              Cały cykl Michaela Turnera (wszystko przed All New Fathom),
              pozycja będzie liczyć ok. tysiąca stron.
            </p>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/serie-69662-BD-Fathom-1998.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/aphrodite-ix.jpg'
              alt='Aphrodite IX'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Aphrodite IX</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>David Wohl, Matt Hawkins</li>
              <li>Ilustracje</li>
              <li>David Finch, Stjepan Šejić</li>
            </ul>
            <p>
              W jednym tomie album z rysunkami Davida Fincha i Rebrith z
              rysunkami Stjepana Šejića.
            </p>
            <p>Więcej informacji na temat zagranicznych wydań:</p>
            <p>
              <a
                href='https://www.bedetheque.com/serie-6104-BD-Aphrodite-IX.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD Aphrodite IX</b>
              </a>
            </p>
            <p>
              <a
                href='https://www.bedetheque.com/serie-81303-BD-Aphrodite-IX-Rebirth.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD Aphrodite IX - Rebirth</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img src='./img/zapowiedzi/cyberforce.jpg' alt='Cyberforce'></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Cyberforce</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Marc Silvestri</li>
              <li>Ilustracje</li>
              <li>Marc Silvestri</li>
            </ul>
            <p>
              W skład albumu wejdzie: CYBERFORCE #0, CYBERFORCE THE TIN MEN OF
              WAR #1-4, THE KILLER INSTINCT CROSSOVER WITH WILDCATS #5-7,
              CYBERFORCE VOLUME 2 #1-13, CYBERFORCE ORIGINS: #1 CYBLADE,
              CYBERFORCE ORIGINS #2 STRYKER and the CYBERFORCE ANNUAL #1
            </p>
            <p>Więcej informacji na temat zagranicznych wydań:</p>
            <p>
              <a
                href='https://www.bedetheque.com/serie-45810-BD-Cyberforce-1992.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD Cyberforce 1992 Mindgames, The tin men of war</b>
              </a>
            </p>
            <p>
              <a
                href='https://www.bedetheque.com/serie-45745-BD-Cyberforce-1993.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD Cyberforce 1993 Killer instinct </b>
              </a>
            </p>
            <p>
              <a
                href='https://www.bedetheque.com/serie-66942-BD-Cyberforce-Origins-1996.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD Cyberforce 1996 Origins</b>
              </a>
            </p>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/hunter-killer.jpeg'
              alt='Hunter-Killer'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Hunter-Killer</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Mark Waid, Marc Silvestri</li>
              <li>Ilustracje</li>
              <li>Marc Silvestri, Eric Basaldua, Kenneth Rocafort</li>
            </ul>
          </div>
        </div>

        <div className={styles.comics_preview}>
          <div className={styles.comics_preview_img}>
            <img
              src='./img/zapowiedzi/le-facteur-cratophane.webp'
              alt='Le Facteur Cratophane'
            ></img>
          </div>
          <div className={styles.comics_preview_info}>
            <h1 className={styles.title}>Le Facteur Cratophane</h1>
            <ul className={`${styles.comics_data} ${styles.main_info}`}>
              <li>Scenariusz</li>
              <li>Éric Liberge</li>
              <li>Ilustracje</li>
              <li>Éric Liberge</li>
            </ul>
            <p>
              Więcej informacji na temat zagranicznego wydania:
              <a
                href='https://www.bedetheque.com/BD-Monsieur-Mardi-Gras-Descendres-Tome-0-Le-Facteur-Cratophane-Prologue-a-Monsieur-Mardi-Gras-Descendres-274543.html'
                target='_blank'
                rel='noreferrer'
              >
                <b> Link BD</b>
              </a>
            </p>
          </div>
        </div>
      </main>
    </>
  );
};

export default Previews;
